import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Ribbons from '../utils/ribbons-effect';
import * as styles from '../styles/module/index.module.scss';

import SEO from '../components/seo';
import Link from '../components/link';

const HomePage = ({ data }) => {
  const {
    site: {
      siteMetadata: { author, paths },
    },
  } = data;

  React.useEffect(() => {
    const RibbonsInstance = new Ribbons();

    return () => {
      RibbonsInstance && RibbonsInstance.remove();
    };
  }, []);

  return (
    <>
      <SEO />

      <main className={styles.content}>
        <div className={styles.box}>
          <h1>
            <p>
              {author.greeting}
              <br />
              <strong>{author.name}</strong>
            </p>
          </h1>
          <p>{author.summary}</p>
        </div>
        <div className={styles.links}>
          <Link to={`${paths.blog}`}>blog</Link>
          <Link to={`mailto:${author.email}`} isNative>
            email
          </Link>
          <Link isNative to={author.linkedIn} target="_blank">
            linkedIn
          </Link>
          <Link isNative to="https://wannabedev.io/" target="_blank">
            wannabedev
          </Link>
        </div>
      </main>
    </>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HomePage;

// Query for site metadata
export const query = graphql`
  query {
    site {
      siteMetadata {
        author {
          email
          name
          summary
          greeting
          linkedIn
        }
        title
        paths {
          blog
        }
      }
    }
  }
`;
